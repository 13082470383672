import Button from '../components/button'
import Page, { Header } from '../components/page'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <>
    <Seo title="Page not found" />
    <Page container>
      <Header
        title="Page not found"
        subtitle="You just hit a page that doesn&#39;t exist... the sadness."
      />
      <Button variant="primary" link={{ to: '/' }}>
        Go to homepage
      </Button>
    </Page>
  </>
)

export default NotFoundPage
